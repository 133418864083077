* {
  margin: 0;
}

.App {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: rgb(42, 42, 42);
}

.big {
  color: white;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 2.5rem;
  letter-spacing: 1px;
  margin-bottom: 0.5rem;
}

.desc {
  font-family: monospace;
  color: white;
}

.text {
  text-align: center;
  margin: 2rem;
}

.playlist {
  max-width: 550px;
  max-height: 580px;
  min-height: 200px;
  height: 500px;
  width: 100%;
  border-radius: 10px;
}

.btn-container {
  margin: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn {
  border: 1px solid #fff;
  border-radius: 25px;
  background-color: transparent;
  font-family: monospace;
  width: 3rem;
  height: 3rem;
  padding: 1rem;
  margin: 0 1rem;
  color: #fff;
  transition: background-color 0.3s, color 0.3s;
}

.btn:hover {
  background-color: #fff;
  color: black;
}

@media (max-width: 440px) {
  .playlist {
    border-radius: 0;
    height: 480px;
  }
}

@media (max-width: 380px) {
  .big {
    font-size: 2rem;
  }

  .playlist {
    height: 420px;
  }
}

@media (max-width: 330px) {
  .big {
    font-size: 1.5rem;
  }

  .playlist {
    height: 320px;
  }
}
